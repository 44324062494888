import {css} from 'lit';
import {url} from "../lit-directives.js";
import close_icon from '../images/close-icon.svg';

// language=css
export default css`
    .close {
        background: ${url(close_icon)} no-repeat center;
        display: inline-block;
        width: 26px;
        height: 26px;
        float: right;
        cursor: pointer;
    }
`;
